import Nav from "../components/general/Nav";
import Hero from "../components/team/Hero";
import Info from "../components/team/Info"
import ToApply from "../components/team/ToApply";
import EBoard from "../components/team/EBoard";
import { CursorContextProvider, Cursor } from "../components/general/Cursor";
import DemoCountdown from "../components/countdown/DemoCountdown";
// TODO add an intro section

function Team() {
  return (
      <CursorContextProvider>
          <Cursor />
          {/* <DemoCountdown /> */}
          <Hero />
          <EBoard />
          <Info />
          <ToApply />
          <Nav color={0} home={1}/>
      </CursorContextProvider>
  )
}

export default Team;