import styled from "styled-components";
import { keyframes } from "styled-components";
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { colors } from "../assets/colors";
import Nav from "../components/general/Nav";
import { useCursorHandlers } from "../components/general/Cursor";
import SocialButton from "../components/general/SocialButton";
import ButtonMenu from "../components/general/ButtonMenu";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import DemoCountdown from "../components/countdown/DemoCountdown";
import Countdown from 'react-countdown';
import logo from "../assets/logo.png";
// TODO add interactive cursor (enlarge on link, add negative, add hover animations to certain links, etc.)

/* ANIMATIONS */

const ExitLoadingScreen = keyframes`
    0% { 
      opacity: 1;
      height: 100vh;
    };
    99% { 
      opacity: 0;
      height: 100vh;
    };
    100% { 
      opacity: 0;
      height: 0vh;
    };

`;

const Typing = keyframes`
    from { width: 0 }
    to { width: 100% }
`;

const BlinkCaret = keyframes`
    50% { border-color: transparent }
`;

const WiggleWiggle = keyframes`
  0% { transform: rotate(0deg); }
  88% { transform: rotate(0deg); }
  90% { transform: rotate(5deg); }
  92% { transform: rotate(0deg); }
  94% { transform: rotate(-5deg); }
  96% { transform: rotate(0deg); }
  98% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
`;

const LoadContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: ${ExitLoadingScreen};
  animation-delay: 2.25s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
`;

const LoadTextContainer = styled.div`
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 3vmin;

  display: grid;
  place-items: center;
`;

const LoadText = styled.h1`
  font-family: ReadexPro, sans-serif;
  width: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #f62d0b;
  font-weight: 200;
  line-height: 82%;
  padding-bottom: 0.5%;

  animation: ${Typing} 1s steps(12),
    ${BlinkCaret} 0.5s step-end infinite alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid ${colors.white};
`;

// set cursor to none on children link hover
const HomeContainer = styled.div`
  height: 100%;
  width: 100vw;
  position: relative;
  cursor: none !important;

  & :hover {
    cursor: none !important;
  }
`;

const HomeSubPageContainer = styled.div`
  @media screen and (max-width: 500px) {
      position: relative;
      height: 100%;
    }
`;

const HomePageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.red};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  transition: background-color 0.2s;
  @media screen and (max-width: 500px) {
      height: 90vh;
      width: 100vw;
    }
`;

const HomeNavContainer = styled.div`
  font-size: 3vmin;
  white-space: nowrap;
  cursor: pointer;
`;

const HomeNavText = styled.h1`
  & {
    font-size: 6vmin;
    font-family: ReadexPro, sans-serif;
    margin: 0;
    padding: 0;
    color: ${colors.black};
    font-weight: 700;
    background-clip: text;
    -webkit-background-clip: text;
    position: relative;
    line-height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  &:before {
    -webkit-text-stroke: 0;
    content: attr(data-title);
    background: ${colors.red};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    transition: all 0.3s ease-out;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  &:hover::before {
    width: 100%;
  }
`;

const LineBreak = styled.div`
  width: 100%;
  height: 0;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  color: #141414;
  display: flex;
  align-items: center;
  transition: all 0.2s;
`;

const ScrollContainer = styled.div`
  width: 250%;
  position: absolute;
  overflow: hidden;
`;

const Scroll = keyframes`
  from {
    transform: translateX(200vw);
  }
  to {
    transform: translateX(-50%);
  }
`;

const ScrollText = styled.h1`
  display: inline-block;
  width: fit-content;
  overflow: hidden;
  font-size: 6vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0 20%;
  padding: 0;
  font-weight: 900;
  color: ${colors.red};
  animation-name: ${Scroll};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

// Former SocialContainer
// const SocialContainer = styled.div`
// 	position: absolute;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	flex-wrap: wrap;
// 	bottom: 3vmax;
// 	right: 4.5vmax;
// `;

const SocialContainer = styled.div`
  width: 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  @media screen and (max-width: 500px) {
      width: 100%;
      justify-content: center;
    }
`;

const InterestContainer = styled.div`
  font-family: ReadexPro, sans-serif;
  font-size: 1.8vh;
  min-width: 10vw;
  border: 1px solid;
  color: ${colors.black};
  border-radius: 3em;
  padding: 1vh 2vw;
  text-align: center;
  margin-right: 20px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.red};
    color: ${colors.white};
  }
`;

const HashTagText = styled.p`
  width: 50%;
  text-align: start;
  position: relative;
  font-size: 2rem;
  font-family: ReadexPro, sans-serif;
  margin: auto;
  color: ${colors.black};
  @media only screen and (max-width: 700px) {
    font-size: 5vmin;
  }
`;

const BottomContentContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  bottom: 3vmax;
  right: 2.5vmax;
  @media screen and (max-width: 500px) {
      font-size: 3.5vmax;
      justify-content: center;
      right: 0vmax;

    }
`;

const MobileCountdown = styled.div`
  @media (min-width: 1024px) {
  display: none;
  }
  @media (max-width: 1024px) {
    margin-top: 5%;
    padding: 5%;
    width: 55vw;
    height: 18vh;
    display: flex;
    justify-content: space-between;
    border: 1vw double ${colors.red}; 
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    color: ${colors.black}
    
  }
`;

const LogoImage = styled.img`
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  padding: 2.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  object-fit: contain;
`;

const RSVP = styled.button`
  display: flex;
  text-align: center;
  cursor: none;
  border-radius: 18px;
  font-size: 75%;
  font-weight: 700;
  color: ${colors.white};
  background-color: ${colors.red};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 2px;
  padding: 24%;
  writing-mode: vertical-rl;
  text-orientation: upright; 
`;


const scrollStatue = [
  [""],
  [
    "WHO WE ARE",
    "OUR MISSION",
    "WHAT WE BELIEVE IN",
    "HOW WE CAN HELP YOU",
    "WHERE YOU FIT",
  ],
  [
    "MEET OUR STARTUPS",
    "WHAT WE'VE DONE",
    "WHERE YOU BELONG",
    "OUR IMPACT",
    "WHAT YOU CAN ADD",
  ],
  [
    "MEET OUR MEMEBRS",
    "GET TO KNOW US",
    "DISCOVER OUR DIVERSITY",
    "EXPLORE OUR PASSIONS",
    "HOW YOU FIT",
  ],
  [
    "SEE THE APPLICATION",
    "VIEW THE TIMELINE",
    "APPLICATION FAQ",
    "HOW TO APPLY",
    "COME JOIN THE FAMILY",
  ],
];

function Home() {
  let location = useLocation();

  const [scrollID, setScrollID] = useState(0);
  const [firstAnimation, setFirstAnimation] = useState(true);

  const normalCursorHandlers = useCursorHandlers();
  const navCursorHandlers = useCursorHandlers({
    hoverOptions: {
      color: colors.white,
    },
    defaultOptions: {
      color: colors.black,
    },
  });

  useEffect(() => {
    if (window.sessionStorage.getItem("FirstLoad") === null) {
      setFirstAnimation(true);
      window.sessionStorage.setItem("FirstLoad", 1);
    } else {
      setFirstAnimation(false);
    }
    
  }, []);

  // const cursor = useContext(CursorContext);
  // cursor.setCursor({active: false, color: colors.black});

  return (
    <HomeContainer>
      {/* <DemoCountdown scrollID={scrollID} setScrollID={setScrollID}/> */}
      <HomeSubPageContainer>
        <HomePageContainer
          style={{
            backgroundColor: scrollID !== 0 ? colors.black : colors.white,
          }}
        >
          {/* <ButtonMenu buttonScrollID={scrollID} {...normalCursorHandlers}/> */}
          <BottomContentContainer>
            <SocialContainer>
              {/* FOUNDER APPLICATION */
              /* <Link to="https://docs.google.com/forms/d/e/1FAIpQLSd44filUaoqHUFg1TfBo-3fNo9eBtMmy1FMvWeistEPmlcTIw/viewform" style={{ textDecoration: "none" }} {...normalCursorHandlers}>
              <InterestContainer
                style={{
                  visibility: !scrollID ? "visible" : "hidden",
                }}
              >
                <BsFillRocketTakeoffFill style={{ marginRight: '0.5em' }}/>
                <p style={{ margin: 0 }}> Become a founder</p>
              </InterestContainer>
            </Link> */}
              <SocialButton
                visible={scrollID}
                name="Slack"
                {...normalCursorHandlers}
              />
              <SocialButton
                visible={scrollID}
                name="Instagram"
                {...normalCursorHandlers}
              />
              <SocialButton
                visible={scrollID}
                name="LinkedIn"
                {...normalCursorHandlers}
              />
              <SocialButton
                visible={scrollID}
                name="Email"
                {...normalCursorHandlers}
              />
            </SocialContainer>
          </BottomContentContainer>
          <LogoContainer style={{ visibility: scrollID === 0 ? "100%" : "0%" }}>
            <Nav color={0} home={0} />
          </LogoContainer>
          <LogoContainer style={{ opacity: scrollID !== 0 ? "100%" : "0%" }}>
            <Nav color={1} home={0} />
          </LogoContainer>

          <Link to="/about" style={{ textDecoration: "none" }}>
            <HomeNavContainer {...navCursorHandlers}>
              <HomeNavText
                data-title="ABOUT US"
                onMouseEnter={() => {
                  setScrollID(1);
                }}
                onMouseLeave={() => {
                  setScrollID(0);
                }}
                style={{
                  WebkitTextStroke:
                    scrollID === 0 ? "transparent" : "1px " + colors.red,
                }}
              >
                ABOUT US
              </HomeNavText>
            </HomeNavContainer>
          </Link>
          <LineBreak />
          <Link to="/portfolio" style={{ textDecoration: "none" }}>
            <HomeNavContainer {...navCursorHandlers}>
              <HomeNavText
                data-title="OUR STARTUPS"
                onMouseEnter={() => {
                  setScrollID(2);
                }}
                onMouseLeave={() => {
                  setScrollID(0);
                }}
                style={{
                  WebkitTextStroke:
                    scrollID === 0 ? "transparent" : "1px " + colors.red,
                }}
              >
                OUR STARTUPS
              </HomeNavText>
            </HomeNavContainer>
          </Link>
          <LineBreak />
          <Link to="/team" style={{ textDecoration: "none" }}>
            <HomeNavContainer {...navCursorHandlers}>
              <HomeNavText
                data-title="MEET THE TEAM"
                onMouseEnter={() => {
                  setScrollID(3);
                }}
                onMouseLeave={() => {
                  setScrollID(0);
                }}
                style={{
                  WebkitTextStroke:
                    scrollID === 0 ? "transparent" : "1px " + colors.red,
                }}
              >
                MEET THE TEAM
              </HomeNavText>
            </HomeNavContainer>
          </Link>
          <LineBreak />
          <Link to="/join-us" style={{ textDecoration: "none" }}>
            <HomeNavContainer {...navCursorHandlers}>
              <HomeNavText
                data-title="JOIN US"
                onMouseEnter={() => {
                  setScrollID(4);
                }}
                onMouseLeave={() => {
                  setScrollID(0);
                }}
                style={{
                  WebkitTextStroke:
                    scrollID === 0 ? "transparent" : "1px " + colors.red,
                }}
              >
                JOIN US
              </HomeNavText>
            </HomeNavContainer>
          </Link>
          <LineBreak />
          <Link to="/support-us" style={{ textDecoration: "none" }}>
            <HomeNavContainer {...navCursorHandlers}>
              <HomeNavText
                data-title="SUPPORT US"
                onMouseEnter={() => {
                  setScrollID(4);
                }}
                onMouseLeave={() => {
                  setScrollID(0);
                }}
                style={{
                  WebkitTextStroke:
                    scrollID === 0 ? "transparent" : "1px " + colors.red,
                }}
              >
                SUPPORT US
              </HomeNavText>
            </HomeNavContainer>
          </Link>
          <LineBreak />
          {/* <MobileCountdown>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
          <div style={{ fontSize: '4.5vmin', fontFamily: 'ReadexPro, sans-serif', fontWeight: '700', textAlign: 'center', marginBottom: '0.5vw' }}> 
            <span style={{ color: colors.red, display: 'block' }}>Fall 2024 <span style={{ color: colors.black, WebkitTextStroke: scrollID === 0 ? "transparent" : "1px " + colors.white }}>Demo</span></span>
            <span style={{ display: 'block', WebkitTextStroke: scrollID === 0 ? "transparent" : "1px " + colors.white }}>Day Countdown</span>
          </div>
          <div style={{
            display: 'flex',
            margin: '10px auto',
            width: '50%', 
            height: '1px', 
            backgroundColor: colors.red, 
          }} />
          <Countdown 
          date={new Date(2024, 10, 18, 18, 0, 0)}
          renderer={({ days, hours, minutes, seconds }) => (
              <div style={{ padding: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '0.9rem', fontWeight: '700', lineHeight: '1.2' }}> 
                  <span style={{ display: 'block', textAlign: 'center', color: scrollID === 0 ? colors.black : colors.white }}>{days} Days {hours} Hours</span>
                  <span style={{ display: 'block', textAlign: 'center', color: scrollID === 0 ? colors.black : colors.white }}>{minutes} Minutes {seconds} Seconds</span>
              </div>
          )}/>
          </div>
          <div style={{  display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '12px', alignItems: 'center' }}>
              <LogoImage src={logo} />
              <RSVP {...normalCursorHandlers} onClick={() => window.open('https://www.eventbrite.com/e/momentum-fall-2024-demo-day-tickets-1061505043159?aff=oddtdtcreator', '_blank')} >RSVP</RSVP>
            </div>
        </MobileCountdown> */}
        </HomePageContainer>
        
      </HomeSubPageContainer>
      <LoadContainer style={{ display: firstAnimation ? "flex" : "none" }}>
        <LoadTextContainer>
          <LoadText>TEXAS MOMENTUM</LoadText>
        </LoadTextContainer>
      </LoadContainer>
    </HomeContainer>
  );
}

export default Home;
